import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";

export const useSignup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (signupData) => {
      const response = await client("auth/signupWithTwitter", {
        method: "POST",
        data: signupData,
      });

      if (response.error) {
        throw new Error(response.error);
      }

      if (response?.data) {
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

export const useVerifyTwitterLogin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (loginData) => {
      const response = await client("auth/verifyTwitter", {
        method: "POST",
        data: loginData,
      });

      if (response.error) {
        throw new Error(response.error);
      }

      if (response?.token?.access_token) {
        localStorage.setItem("token", response.token?.access_token);
        queryClient.invalidateQueries({ queryKey: ["token"] });
        queryClient.invalidateQueries({ queryKey: ["getWallet"] });
        queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

export const useGetLoggedInUser = () =>
  useQuery({
    queryKey: ["getLoggedInUser"],
    queryFn: async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        return null;
      }
      const response = await client(`auth/getLoggedInUser`);
      return response;
    },
  });

export const useLogout = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const response = await client("auth/revoke", {
        method: "POST",
      });
      localStorage.clear();
      queryClient.invalidateQueries({ queryKey: ["token"] });
    },
  });
};

export const useGetWallet = () =>
  useQuery({
    queryKey: ["getWallet"],
    queryFn: async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        return null;
      }
      const response = await client(`auth/getWallet`);
      return response;
    },
  });

export const useAddWalletAddress = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (walletData) => {
      const response = await client("auth/verifyWalletAddress", {
        method: "POST",
        data: walletData,
      });

      if (response.error) {
        throw new Error(response.error);
      }

      if (response) {
        queryClient.invalidateQueries({ queryKey: ["getWallet"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

export const useUpdateWalletAddress = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (walletData) => {
      const response = await client("auth/updateWalletAddress", {
        method: "POST",
        data: walletData,
      });

      if (response.error) {
        throw new Error(response.error);
      }

      if (response) {
        queryClient.invalidateQueries({ queryKey: ["getWallet"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};
