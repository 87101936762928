import {
  Box,
  Flex,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PrimaryText from "./primaryText";
import {
  IconBrandX,
  IconCircleCheck,
  IconCircleCheckFilled,
  IconStar,
  IconStarFilled,
  IconTrophy,
} from "@tabler/icons-react";
import { useGetLeaderboard } from "../utils/tweet.api";
import PrimaryButton from "./PrimaryButton";

function Leaderboard() {
  const [limit, setLimit] = useState(10);
  const [type, setType] = useState("current");
  const [filter, setFilter] = useState("monthly");

  const toast = useToast();

  const {
    data: leaderboard,
    isLoading,
    refetch,
  } = useGetLeaderboard(limit, 0, type, filter);

  useEffect(() => {
    refetch();
  }, [type, filter]);

  return (
    <Flex flexDir="column" gap="10px" color="#fff" py="20px">
      <Flex justify="space-between" align="center" gap="10px">
        <PrimaryText size="18px" weight="700" text="Leaderboard" />
        <Flex>
          <Select
            fontSize="12px"
            p="2px"
            height="30px"
            border="1px solid #fff"
            _focus={{
              borderColor: "#fff",
            }}
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
          >
            <option
              value="monthly"
              style={{
                backgroundColor: "#000",
              }}
            >
              Monthly Leaderboard
            </option>
            <option
              value="yearly"
              style={{
                backgroundColor: "#000",
              }}
            >
              Yearly Leaderboard
            </option>
          </Select>
          <Select
            fontSize="12px"
            p="2px"
            height="30px"
            border="1px solid #fff"
            _focus={{
              borderColor: "#fff",
            }}
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <option
              value="current"
              style={{
                backgroundColor: "#000",
              }}
            >
              This {filter === "monthly" ? "Month" : "Year"}
            </option>
            <option
              value="previous"
              style={{
                backgroundColor: "#000",
              }}
            >
              Last {filter === "monthly" ? "Month" : "Year"}
            </option>
          </Select>
        </Flex>
      </Flex>
      <PrimaryText
        size="14px"
        weight="500"
        text="The current contest period ends on July 31st, 2024. At the end of the contest, 10 million SLORP will be airdropped to the 100 leaderboard positions shown below."
      />
      {isLoading ? (
        <Flex justify="center" mt="10px">
          <Spinner size="sm" color="#EEDE00" />
        </Flex>
      ) : !leaderboard?.length ? (
        <Flex justify="center" mt="10px">
          <PrimaryText text="No data found" size="14px" weight="600" />
        </Flex>
      ) : (
        <TableContainer>
          <Table>
            <Tbody>
              {leaderboard?.map((rank, idx) => (
                <Tr key={idx}>
                  <Td borderStyle="dotted">
                    <Flex flexDir="column">
                      <Flex align="center" gap="4px">
                        <IconTrophy size="14px" />
                        <PrimaryText text="Rank" size="12px" weight="600" />
                        <PrimaryText
                          text={`#${idx + 1}`}
                          size="12px"
                          weight="600"
                        />
                      </Flex>
                      <PrimaryText
                        text={`${
                          rank?.walletAddress
                            ? rank?.walletAddress?.slice(0, 6) + "..."
                            : "" || ""
                        }`}
                        size="12px"
                        weight="600"
                        color="#eede00"
                        cursor="pointer"
                        onClick={() => {
                          if (rank?.walletAddress) {
                            navigator.clipboard
                              .writeText(rank?.walletAddress)
                              .then(() => {
                                toast({
                                  description: "Copied to clipboard",
                                  status: "success",
                                  duration: 3000,
                                  isClosable: true,
                                });
                              });
                          }
                        }}
                      />
                    </Flex>
                  </Td>
                  <Td borderStyle="dotted">
                    <Flex align="center" gap="4px">
                      <IconBrandX size="14px" />
                      <PrimaryText
                        text={`@${rank?.username || "slorp"}`}
                        size="12px"
                        weight="600"
                        underline="underline"
                      />
                    </Flex>
                  </Td>
                  <Td borderStyle="dotted">
                    <Flex flexDir="column">
                      <Flex align="center" gap="4px">
                        <IconStarFilled size="14px" />
                        <PrimaryText text="Score:" size="12px" weight="600" />
                        <PrimaryText
                          text={`${rank?.score || 0} Points`}
                          size="12px"
                          weight="600"
                        />
                      </Flex>
                      {rank?.isQualified && idx < 100 && (
                        <Flex align="center" gap="4px">
                          <IconCircleCheckFilled size="14px" color="green" />
                          <PrimaryText
                            text="Qualified for Airdrop"
                            size="12px"
                            weight="600"
                          />
                        </Flex>
                      )}
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {leaderboard?.length >= limit && (
            <Box mt="20px">
              <PrimaryButton
                text="View More"
                onClick={() => {
                  setLimit(limit + 10);
                }}
              />
            </Box>
          )}
        </TableContainer>
      )}
    </Flex>
  );
}

export default Leaderboard;
