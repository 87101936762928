import { Button, Flex, Image, Spinner, useToast } from "@chakra-ui/react";
import React from "react";
import PrimaryText from "./primaryText";
import { IconArrowRight, IconBrandX } from "@tabler/icons-react";
import SlorpLogo from "../assets/imgs/slorpcoin.png";
import { useSignup } from "../utils/auth.api";

function NotConnected({ isPending }) {
  const twitterLogin = useSignup();

  const toast = useToast();

  const handleLogin = async () => {
    await twitterLogin
      .mutateAsync({
        redirectUrl: window?.origin,
      })
      .then((result) => {
        window?.open(result?.data, "_self");
      })
      .catch((err) => {
        toast({
          description: err?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
    // setIsConnected(true);
  };

  return (
    <Flex flexDir="column" p="50px" align="center" gap="20px">
      <Image src={SlorpLogo} height={40} width={40} />
      <Flex align="center" flexDir="column" gap="4px">
        <PrimaryText
          color="#fff"
          size="20px"
          weight="700"
          text="Slorp Token (SLORP) Farming"
        />
        <PrimaryText
          color="#fff"
          size="12px"
          weight="700"
          text="Earn your share of 10 Million SLORP monthly and yearly by posting about @SlorpCoin on X/Twitter"
        />
        <PrimaryText
          color="#fff"
          size="12px"
          weight="700"
          text="Sign in below to get started"
        />
      </Flex>
      <Button
        borderRadius="0px"
        bg="rgba(0, 0, 0, 1)"
        gap="15px"
        py="22px"
        _hover={{
          bg: "rgba(0, 0, 0, 1)",
        }}
        onClick={handleLogin}
        isDisabled={twitterLogin?.isPending || isPending}
      >
        {twitterLogin?.isPending || isPending ? (
          <Spinner size="sm" color="#fff" />
        ) : (
          <>
            <IconBrandX color="#fff" />
            <PrimaryText
              color="#fff"
              size="12px"
              weight="500"
              text="Sign in with X/Twitter"
            />
            <IconArrowRight color="#fff" />
          </>
        )}
      </Button>
    </Flex>
  );
}

export default NotConnected;
