import { Flex, Spinner } from "@chakra-ui/react";
import React, { useState } from "react";
import PrimaryText from "./primaryText";
import { useGetTweets } from "../utils/tweet.api";
import TweetCard from "./TweetCard";
import PrimaryButton from "./PrimaryButton";

function Tweets() {
  const [limit, setLimit] = useState(10);

  const { data: tweets, isLoading } = useGetTweets(limit, 0);

  return (
    <Flex py="20px" color="#fff" gap="10px" flexDir="column">
      <PrimaryText size="18px" weight="700" text="My Tweets" />
      <PrimaryText
        size="14px"
        weight="500"
        text="Below is a list of your tweets. Please note that our systems may take up to 3 hours to assign points to your latest tweets."
      />
      {isLoading ? (
        <Flex mt="10px" justify="center">
          <Spinner size="sm" color="#EEDE00" />
        </Flex>
      ) : !tweets?.length ? (
        <Flex mt="10px" justify="center">
          <PrimaryText size="12px" weight="500" text="No tweets found" />
        </Flex>
      ) : (
        <Flex flexDir="column" gap="10px" w="100%">
          {tweets?.map((tweet, index) => (
            <TweetCard key={index} tweet={tweet} />
          ))}
          {tweets?.length >= limit && (
            <PrimaryButton
              text="View More"
              onClick={() => setLimit(limit + 10)}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
}

export default Tweets;
