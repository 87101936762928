import { useQuery } from "@tanstack/react-query";
import { client } from "./api-client";

export const useGetLeaderboard = (limit, offset, type, filter) =>
  useQuery({
    queryKey: ["getLeaderboard", limit, offset, type, filter],
    queryFn: async () => {
      const response = await client(
        `tweet/topLeaderBoard?limit=${limit}&offset=${offset}&type=${type}&filter=${filter}`
      );
      return response;
    },
  });

export const useGetTweets = (limit, offset) =>
  useQuery({
    queryKey: ["getTweets", limit, offset],
    queryFn: async () => {
      const response = await client(
        `tweet/getTweetsOfUser?limit=${limit}&offset=${offset}`
      );
      return response;
    },
  });

export const useGetCoinPrice = () =>
  useQuery({
    queryKey: ["getCoinPrice"],
    queryFn: async () => {
      const response = await client(`tweet/getCoinPrice`);
      return response;
    },
    staleTime: 10000,
  });
