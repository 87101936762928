import { Avatar, Box, Flex } from "@chakra-ui/react";
import React from "react";
import PrimaryText from "./primaryText";
import {
  IconArrowBackUp,
  IconHeart,
  IconMessageDots,
  IconRepeat,
} from "@tabler/icons-react";

function TweetCard({ tweet }) {
  return (
    <Box border="1px solid #EEDE00" p="10px">
      <Flex gap="10px" align="center">
        <Avatar
          boxSize="48px"
          src={tweet?.user?.profilePicUrl}
          name={tweet?.user?.name || tweet?.user?.screen_name}
        />
        <Flex flexDir="column" gap="2px">
          <PrimaryText
            size="14px"
            weight="700"
            text={tweet?.user?.name || ""}
          />
          <PrimaryText
            size="10px"
            weight="500"
            color="#EEDE00"
            text={`@${tweet?.user?.screen_name}` || ""}
          />
        </Flex>
      </Flex>
      <Box mt="10px">
        <PrimaryText size="12px" weight="500" text={tweet?.text || ""} />
      </Box>
      <Flex
        bg="#181818"
        p="12px"
        align="center"
        gap="12px"
        justify="space-between"
        wrap="wrap"
        mt="10px"
      >
        <Flex align="center" gap="5px">
          <IconRepeat size="12px" color="#fff" />
          <PrimaryText
            color="#EEDE00"
            size="12px"
            weight="700"
            text={tweet?.retweets || 0}
          />
          <PrimaryText
            color="#737373"
            size="12px"
            weight="600"
            text="Retweets"
          />
        </Flex>
        <Flex align="center" gap="5px">
          <IconHeart size="12px" color="#fff" />
          <PrimaryText
            color="#EEDE00"
            size="12px"
            weight="700"
            text={tweet?.likes || 0}
          />
          <PrimaryText color="#737373" size="12px" weight="600" text="Likes" />
        </Flex>
        <Flex align="center" gap="5px">
          <IconMessageDots size="12px" color="#fff" />
          <PrimaryText
            color="#EEDE00"
            size="12px"
            weight="700"
            text={tweet?.quotes || 0}
          />
          <PrimaryText color="#737373" size="12px" weight="600" text="Quotes" />
        </Flex>
        <Flex align="center" gap="5px">
          <IconArrowBackUp size="12px" color="#fff" />
          <PrimaryText
            color="#EEDE00"
            size="12px"
            weight="700"
            text={tweet?.replies || 0}
          />
          <PrimaryText
            color="#737373"
            size="12px"
            weight="600"
            text="Replies"
          />
        </Flex>
      </Flex>
    </Box>
  );
}

export default TweetCard;
