import { Button } from "@chakra-ui/react";
import React from "react";

function PrimaryButton({ text, onClick }) {
  return (
    <Button
      bg="#EEDE00"
      borderRadius="0"
      onClick={onClick}
      height="42px"
      width="100%"
      _hover={{
        bg: "#EEDE00",
      }}
    >
      {text}
    </Button>
  );
}

export default PrimaryButton;
