import { Box, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import PrimaryText from "./primaryText";
import PrimaryButton from "./PrimaryButton";
import {
  IconArrowBackUp,
  IconEye,
  IconHeart,
  IconMessageDots,
  IconRepeat,
} from "@tabler/icons-react";
import Leaderboard from "./Leaderboard";
import Tweets from "./Tweets";

function Connected({ user, isLoading }) {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <Box p="12px">
      <Flex align="center" gap="8px">
        <Flex
          bg={activeTab === 1 ? "transparent" : "rgba(0,0,0,0.9)"}
          borderBottom={
            activeTab === 1 ? "2px solid #EEDE00" : "2px solid transparent"
          }
          w="33%"
          justify="center"
          align="center"
          height="42px"
          cursor="pointer"
          onClick={() => setActiveTab(1)}
        >
          <PrimaryText
            color={activeTab === 1 ? "#EEDE00" : "#fff"}
            size="14px"
            weight="600"
            text="How it Works"
          />
        </Flex>
        <Flex
          bg={activeTab === 2 ? "transparent" : "rgba(0,0,0,0.9)"}
          borderBottom={
            activeTab === 2 ? "2px solid #fff" : "2px solid transparent"
          }
          w="33%"
          justify="center"
          align="center"
          height="42px"
          cursor="pointer"
          onClick={() => setActiveTab(2)}
        >
          <PrimaryText
            color={activeTab === 2 ? "#EEDE00" : "#fff"}
            size="14px"
            weight="600"
            text="Leaderboard"
          />
        </Flex>
        <Flex
          bg={activeTab === 3 ? "transparent" : "rgba(0,0,0,0.9)"}
          borderBottom={
            activeTab === 3 ? "2px solid #fff" : "2px solid transparent"
          }
          w="33%"
          justify="center"
          align="center"
          height="42px"
          cursor="pointer"
          onClick={() => setActiveTab(3)}
        >
          <PrimaryText
            color={activeTab === 3 ? "#EEDE00" : "#fff"}
            size="14px"
            weight="600"
            text="My Tweets"
          />
        </Flex>
        <Box w="33%">
          <PrimaryButton
            text="Tweet Now"
            onClick={() => {
              window?.open(
                "https://x.com/intent/tweet?text=Earn free $SLORP Coin by sharing @SlorpCoin on X/Twitter. It's time to ditch your old self and get Slorpified! Trust in SLORP for real financial freedom."
              );
            }}
          />
        </Box>
      </Flex>
      {activeTab === 1 && (
        <Flex mt="20px" flexDir="column" gap="12px">
          <PrimaryText
            color="#fff"
            size="18px"
            weight="700"
            text="Introduction"
          />
          <PrimaryText
            color="#fff"
            size="14px"
            weight="600"
            text="Add your SLORP wallet address above to be eligible for airdrops."
          />
          <PrimaryText
            color="#fff"
            size="14px"
            weight="600"
            text="Earn Slorp Tokens (SLORP) by posting about @SlorpCoin or @SlorpETH on X/Twitter. The more engagement your tweets get, the more points you collect, and the higher you move up the leaderboard."
          />
          <PrimaryText
            color="#fff"
            size="14px"
            weight="600"
            text="Scores are kept both monthly and yearly. Every month and every year, a 10 Million SLORP airdrop will be awarded to the top 100 spots on the monthly and yearly leaderboards."
          />
          <PrimaryText
            color="#fff"
            size="16px"
            weight="700"
            text="How To Collect Points"
          />
          <PrimaryText
            color="#fff"
            size="14px"
            weight="600"
            text="Everytime you post a tweet and mention @SlorpCoin or @SlorpETH our system will analyze the engagement on that tweet for 24 hours. The more views, likes, retweets, quotes and replies it gets, the more points are added to your score. Our systems only count a maximum of 5 tweets per day."
          />
          <Flex gap="20px" wrap="wrap" align="center">
            <Flex flexDir="column" gap="2px">
              <Flex gap="6px" align="center">
                <IconEye color="#EEDE00" size="16px" />
                <PrimaryText
                  color="#fff"
                  size="12px"
                  weight="600"
                  text="Views"
                />
              </Flex>
              <PrimaryText
                color="#737373"
                size="12px"
                weight="600"
                text="1 point / view"
              />
            </Flex>
            <Flex flexDir="column" gap="2px">
              <Flex gap="6px" align="center">
                <IconHeart color="#EEDE00" size="16px" />
                <PrimaryText
                  color="#fff"
                  size="12px"
                  weight="600"
                  text="Likes"
                />
              </Flex>
              <PrimaryText
                color="#737373"
                size="12px"
                weight="600"
                text="5 points / like"
              />
            </Flex>
            <Flex flexDir="column" gap="2px">
              <Flex gap="6px" align="center">
                <IconArrowBackUp color="#EEDE00" size="16px" />
                <PrimaryText
                  color="#fff"
                  size="12px"
                  weight="600"
                  text="Replies"
                />
              </Flex>
              <PrimaryText
                color="#737373"
                size="12px"
                weight="600"
                text="10 points / reply"
              />
            </Flex>
            <Flex flexDir="column" gap="2px">
              <Flex gap="6px" align="center">
                <IconRepeat color="#EEDE00" size="16px" />
                <PrimaryText
                  color="#fff"
                  size="12px"
                  weight="600"
                  text="Retweets"
                />
              </Flex>
              <PrimaryText
                color="#737373"
                size="12px"
                weight="600"
                text="12 points / retweet"
              />
            </Flex>
            <Flex flexDir="column" gap="2px">
              <Flex gap="6px" align="center">
                <IconMessageDots color="#EEDE00" size="16px" />
                <PrimaryText
                  color="#fff"
                  size="12px"
                  weight="600"
                  text="Quotes"
                />
              </Flex>
              <PrimaryText
                color="#737373"
                size="12px"
                weight="600"
                text="15 points / quote"
              />
            </Flex>
          </Flex>
          <PrimaryButton
            text="Write Your Own Tweet"
            onClick={() => {
              window?.open(
                "https://x.com/intent/tweet?text=Earn free $SLORP Coin by sharing @SlorpCoin on X/Twitter. It's time to ditch your old self and get Slorpified! Trust in SLORP for real financial freedom."
              );
            }}
          />
          <PrimaryText
            color="#fff"
            size="16px"
            weight="700"
            text="How Do I Know I Qualify For an Airdrop?"
          />
          <PrimaryText
            color="#fff"
            size="14px"
            weight="600"
            text={`If you maintain a rank of #100 or lower on the monthly or yearly leaderboard, you qualify for the monthly or yearly airdrop. At the top right you will see a green checkmark or a red "x" icon, indicating whether or not you qualify for the respective airdrop. Hover your mouse or click the score section at the top right to see details.`}
          />
          <PrimaryText
            color="#737373"
            size="10px"
            weight="600"
            text="Please note that the use of engagement bots, as well as the mentioning of other, unrelated farming campaigns is not permitted. In such cases, we reserve the right to remove the associated participant from the competition."
          />
        </Flex>
      )}
      {activeTab === 2 && <Leaderboard />}
      {activeTab === 3 && <Tweets />}
    </Box>
  );
}

export default Connected;
