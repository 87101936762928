import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useOutsideClick,
  useToast,
} from "@chakra-ui/react";
import BgImg from "./assets/imgs/bg.svg";
import PrimaryText from "./components/primaryText";
import {
  IconArrowBackUp,
  IconCircleXFilled,
  IconHeart,
  IconMessageDots,
  IconPencil,
  IconPlus,
  IconRepeat,
  IconStarFilled,
  IconTrophy,
} from "@tabler/icons-react";
import NotConnected from "./components/NotConnected";
import Connected from "./components/Connected";
import SlorpLogo from "./assets/imgs/slorpcoin.png";
import SlorpPriest from "./assets/imgs/SlorpPriest.png";
import { useQuery } from "@tanstack/react-query";
import {
  useGetLoggedInUser,
  useLogout,
  useVerifyTwitterLogin,
  useAddWalletAddress,
  useGetWallet,
  useUpdateWalletAddress,
} from "./utils/auth.api";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetCoinPrice } from "./utils/tweet.api";

function App() {
  const { data: token } = useQuery({
    queryKey: ["token"],
    queryFn: async () => {
      return localStorage.getItem("token");
    },
  });
  const { data: user, isLoading } = useGetLoggedInUser();
  const { data: coinPrice } = useGetCoinPrice();
  const { data: walletInfo } = useGetWallet();

  const logout = useLogout();
  const verifyTwitter = useVerifyTwitterLogin();
  const addWalletAddress = useAddWalletAddress();
  const updateWalletAddress = useUpdateWalletAddress();

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isUpdateOpen,
    onOpen: onUpdateOpen,
    onClose: onUpdateClose,
  } = useDisclosure();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const state = searchParams.get("state");
  const code = searchParams.get("code");

  const menuRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [slorpAddress, setSlopAddress] = useState("");

  const handleSlorpAdressSubmission = (e) => {
    e.preventDefault();
    addWalletAddress
      ?.mutateAsync({
        address: slorpAddress,
      })
      .then((result) => {
        toast({
          description: "Wallet address added successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          description: err?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleAddressUpdate = (e) => {
    e.preventDefault();
    updateWalletAddress
      ?.mutateAsync({
        address: slorpAddress,
      })
      .then((result) => {
        toast({
          description: "Wallet address updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onUpdateClose();
      })
      .catch((err) => {
        toast({
          description: err?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  useOutsideClick({
    ref: menuRef,
    handler: () => setIsMenuOpen(false),
  });

  useEffect(() => {
    if (state && code && !verifyTwitter?.isPending) {
      verifyTwitter
        .mutateAsync({
          state,
          code,
          redirectUrl: window?.origin,
        })
        .then((result) => {
          toast({
            description: "Login successful",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          navigate("/");
        })
        .catch((err) => {
          toast({
            description: err?.message || "An error occurred",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  }, [code]);

  return (
    <Box
      bgColor="#181818"
      minH="100vh"
      bgImage={BgImg}
      bgSize="contain"
      bgRepeat="no-repeat"
      bgPos="center"
    >
      <Box maxW="500px" width="100%" mx="auto" py="50px">
        <Box border="2px dashed #EEDE00">
          <Flex
            p="10px"
            justify="space-between"
            align="center"
            gap="20px"
            bg="#000"
            w="100%"
          >
            <Flex align="center" gap="12px">
              <Image src={SlorpLogo} height={"40px"} width={"40px"} />
              <Flex flexDir="column" gap="4px">
                <Flex align="center" gap="6px">
                  <PrimaryText
                    color="#fff"
                    size="14px"
                    weight="600"
                    text={`$${coinPrice?.priceInUsd || 0.0}`}
                  />
                  {/* <PrimaryText
                    color="red"
                    size="12px"
                    weight="500"
                    text="-0.005"
                  /> */}
                </Flex>
                <PrimaryText
                  color="#EEDE00"
                  size="12px"
                  weight="500"
                  text="SLORP/SOL"
                />
              </Flex>
            </Flex>
            <Flex align="center" gap="6px">
              <Flex flexDir="column" gap="4px">
                <Flex align="center" gap="4px">
                  <PrimaryText
                    color="#fff"
                    size="12px"
                    weight="500"
                    text="Monthly"
                  />
                  <IconStarFilled size="12px" color="#fff" />
                  <PrimaryText
                    color="#fff"
                    size="12px"
                    weight="500"
                    text={user?.monthlyPoints || 0}
                  />
                  <IconTrophy size="12px" color="#fff" />
                  {Number(user?.monthlyRank) > 0 ? (
                    <PrimaryText
                      color="#fff"
                      size="12px"
                      weight="500"
                      text={user?.monthlyRank || 0}
                    />
                  ) : (
                    <>
                      <PrimaryText
                        color="#fff"
                        size="12px"
                        weight="500"
                        text="No Rank"
                      />
                      <IconCircleXFilled size="12px" color="red" />
                    </>
                  )}
                </Flex>
                <Flex align="center" gap="4px" justify="end">
                  <PrimaryText
                    color="#fff"
                    size="12px"
                    weight="500"
                    text="Yearly"
                  />
                  <IconStarFilled size="12px" color="#fff" />
                  <PrimaryText
                    color="#fff"
                    size="12px"
                    weight="500"
                    text={user?.yearlyPoints || 0}
                  />
                  <IconTrophy size="12px" color="#fff" />
                  {Number(user?.yearlyRank) > 0 ? (
                    <PrimaryText
                      color="#fff"
                      size="12px"
                      weight="500"
                      text={user?.yearlyRank || 0}
                    />
                  ) : (
                    <>
                      <PrimaryText
                        color="#fff"
                        size="12px"
                        weight="500"
                        text="No Rank"
                      />
                      <IconCircleXFilled size="12px" color="red" />
                    </>
                  )}
                </Flex>
              </Flex>
              <Box pos="relative">
                {isLoading ? (
                  <Spinner size="sm" color="#fff" />
                ) : token ? (
                  <Image
                    src={user?.profilePicUrl || SlorpPriest}
                    height={"40px"}
                    width={"40px"}
                    cursor="pointer"
                    onClick={() => {
                      setIsMenuOpen(!isMenuOpen);
                    }}
                  />
                ) : (
                  <Image src={SlorpPriest} height={"40px"} width={"40px"} />
                )}
                {isMenuOpen && (
                  <Flex
                    ref={menuRef}
                    top="0"
                    right="0"
                    mt="45px"
                    align="center"
                    gap="10px"
                    pos="absolute"
                    bg="#000"
                    border="1px solid #EEDE00"
                    p="4px 10px"
                  >
                    <PrimaryText
                      color="#fff"
                      size="12px"
                      weight="500"
                      text={`@${user?.username}` || ""}
                    />
                    {logout?.isPending ? (
                      <Spinner size="sm" color="#EEDE00" />
                    ) : (
                      <PrimaryText
                        color="#EEDE00"
                        size="12px"
                        weight="500"
                        text="Logout"
                        cursor="pointer"
                        onClick={() => {
                          logout
                            .mutateAsync()
                            .then((result) => {
                              setIsMenuOpen(false);
                            })
                            .catch((error) => {
                              setIsMenuOpen(false);
                            });
                        }}
                      />
                    )}
                  </Flex>
                )}
              </Box>
            </Flex>
          </Flex>
          {token && (
            <Box bg="#000" px="10px">
              {walletInfo?.walletAddress ? (
                <Flex p="10px" gap="4px">
                  <PrimaryText
                    color="#fff"
                    size="12px"
                    weight="500"
                    text={`Wallet Address:`}
                  />
                  <PrimaryText
                    color="#eede00"
                    size="12px"
                    weight="500"
                    text={walletInfo?.walletAddress?.slice(0, 12) + "..." || ""}
                    cursor="pointer"
                    onClick={() => {
                      navigator.clipboard
                        .writeText(walletInfo?.walletAddress)
                        .then(() => {
                          toast({
                            description: "Copied to clipboard",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                          });
                        });
                    }}
                  />
                  <IconPencil
                    color="#fff"
                    size={16}
                    onClick={() => {
                      setSlopAddress(walletInfo?.walletAddress);
                      onUpdateOpen();
                    }}
                  />
                </Flex>
              ) : (
                <Button
                  mx="10px"
                  my="10px"
                  border="0"
                  color="#eede00"
                  gap="5px"
                  p="0"
                  alignItems="center"
                  bg="transparent"
                  _hover={{
                    bg: "tranparent",
                  }}
                  _focus={{
                    bg: "transparent",
                  }}
                  onClick={onOpen}
                >
                  <IconPlus size="16px" />
                  <Text fontSize="10px">Add Slorp Wallet Address</Text>
                </Button>
              )}
            </Box>
          )}
          <Flex
            bg="#181818"
            p="12px"
            align="center"
            gap="12px"
            justify="space-between"
            wrap="wrap"
          >
            <Flex align="center" gap="5px">
              <IconRepeat size="12px" color="#fff" />
              <PrimaryText
                color="#EEDE00"
                size="12px"
                weight="700"
                text={user?.totalRetweets || 0}
              />
              <PrimaryText
                color="#737373"
                size="12px"
                weight="600"
                text="Retweets"
              />
            </Flex>
            <Flex align="center" gap="5px">
              <IconHeart size="12px" color="#fff" />
              <PrimaryText
                color="#EEDE00"
                size="12px"
                weight="700"
                text={user?.totalLikes || 0}
              />
              <PrimaryText
                color="#737373"
                size="12px"
                weight="600"
                text="Likes"
              />
            </Flex>
            <Flex align="center" gap="5px">
              <IconMessageDots size="12px" color="#fff" />
              <PrimaryText
                color="#EEDE00"
                size="12px"
                weight="700"
                text={user?.totalQuotes || 0}
              />
              <PrimaryText
                color="#737373"
                size="12px"
                weight="600"
                text="Quotes"
              />
            </Flex>
            <Flex align="center" gap="5px">
              <IconArrowBackUp size="12px" color="#fff" />
              <PrimaryText
                color="#EEDE00"
                size="12px"
                weight="700"
                text={user?.totalReplies || 0}
              />
              <PrimaryText
                color="#737373"
                size="12px"
                weight="600"
                text="Replies"
              />
            </Flex>
          </Flex>
          <Box bg="rgba(0, 0, 0, 0.5)">
            {token ? (
              <Connected user={user} isLoading={isLoading} />
            ) : (
              <NotConnected isPending={verifyTwitter?.isPending} />
            )}
            <Flex align="center" justify="space-between" p="12px">
              <PrimaryText
                color="#EEDE00"
                size="12px"
                weight="500"
                text={`Slorp © ${new Date()?.getFullYear()} `}
              />
              <Flex align="center" gap="10px">
                <PrimaryText
                  color="#737373"
                  size="12px"
                  weight="500"
                  text="Terms & Conditions"
                  cursor="pointer"
                  underline="underline"
                />
                <PrimaryText
                  color="#737373"
                  size="12px"
                  weight="500"
                  text="Privacy Policy"
                  cursor="pointer"
                  underline="underline"
                />
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={() => {}} isCentered>
        <ModalOverlay />
        <ModalContent bg="#000" color="#fff" border="2px dashed #eede00">
          <ModalHeader fontSize="12px">Add Slorp Wallet Address</ModalHeader>
          <form onSubmit={handleSlorpAdressSubmission}>
            <ModalBody>
              <Input
                placeholder="Enter slorp wallet address"
                value={slorpAddress}
                required
                onChange={(e) => {
                  setSlopAddress(e.target.value);
                }}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="secondary"
                mr={3}
                onClick={() => {
                  onClose();
                  setSlopAddress("");
                }}
                isDisabled={addWalletAddress?.isPending}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="ghost"
                bg="#eede00"
                _hover={{
                  bg: "#eede00",
                }}
                isLoading={addWalletAddress?.isPending}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Modal isOpen={isUpdateOpen} onClose={() => {}} isCentered>
        <ModalOverlay />
        <ModalContent bg="#000" color="#fff" border="2px dashed #eede00">
          <ModalHeader fontSize="12px">Edit Slorp Wallet Address</ModalHeader>
          <form onSubmit={handleAddressUpdate}>
            <ModalBody>
              <Input
                placeholder="Enter slorp wallet address"
                value={slorpAddress}
                required
                onChange={(e) => {
                  setSlopAddress(e.target.value);
                }}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="secondary"
                mr={3}
                onClick={() => {
                  onUpdateClose();
                  setSlopAddress("");
                }}
                isDisabled={updateWalletAddress?.isPending}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="ghost"
                bg="#eede00"
                _hover={{
                  bg: "#eede00",
                }}
                isDisabled={updateWalletAddress?.isPending}
                isLoading={updateWalletAddress?.isPending}
              >
                Update
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default App;
