import { Text } from "@chakra-ui/react";
import React from "react";

function PrimaryText({
  color,
  text,
  size,
  weight,
  align,
  cursor,
  onClick,
  underline,
}) {
  return (
    <Text
      color={color}
      fontSize={size}
      fontWeight={weight}
      textAlign={align}
      cursor={cursor}
      onClick={onClick}
      textDecor={underline}
    >
      {text}
    </Text>
  );
}

export default PrimaryText;
